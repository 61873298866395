<template>
  <section>
    <title-section
      title="Guías de selección"
    >
      <div class="level-item">
        <router-link class="button button_micro_dark" :to="{ name: 'agregar-guia-de-seleccion' }">
          AGREGAR GUÍA
        </router-link>
      </div>
    </title-section>
    <selection-guide-main />
  </section>
</template>

<script>
export default {
  name: 'SelectionGuide',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    SelectionGuideMain: () => import('@/components/SelectionGuide/SelectionGuideMain.vue')
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 170px;
  }
</style>
